<template lang="pug">
  .edit_view
    LoadingItem(v-if="loading")
    MainHeadItem(iconName="edit" iconW="30" iconH="20" title="社員編集")
      IconEdit
    ProfileImg(
      :id="account.id"
      :email="account.email"
      :profileImgName="account.profileImgName"
      :profileImgUrl="account.profileImgUrl"
      :isAccount="false"
    )
    AccountForm(:formData="formData" @submit="onRegister($event)")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import MainHeadItem from '@/components/MainHeadItem.vue'
import IconEdit from '@/components/icons/IconEdit.vue'
import LoadingItem from '@/components/LoadingItem'
import ProfileImg from '@/components/ProfileImg'
import AccountForm from '@/components/admin/AccountForm.vue'
export default {
  name: 'AdminRegisterView',
  metaInfo() {
    return {
      title:
        '【管理】' +
        this.users[this.$route.params['id']].name +
        'さんアカウント編集画面',
    }
  },
  components: {
    MainHeadItem,
    IconEdit,
    LoadingItem,
    ProfileImg,
    AccountForm,
  },
  data() {
    return {
      loading: false,
      account: {},
      formData: {},
    }
  },
  computed: {
    ...mapState('usersModule', ['users']),
  },
  created() {
    this.account = this.users[this.$route.params['id']]
    this.formData.isAdmin = this.account.isAdmin
    this.formData.id = this.account.id
    this.formData.name = this.account.name
    this.formData.nameEn = this.account.nameEn
    this.formData.gender = this.account.gender
    this.formData.email = this.account.email
    this.formData.tel = this.account.tel
    this.formData.hireDate = new Date(this.account.hireDate.seconds * 1000)
    this.formData.departmentId = this.account.departmentId
    this.formData.positionId = this.account.positionId
  },
  methods: {
    ...mapActions('usersModule', ['updateUser']),
    async onRegister(event) {
      this.loading = true
      // ID変更時は画像をリネームできないため一旦そのまま
      await this.updateUser({
        email: this.account.email,
        updateData: event.formData,
      })
      this.$router.push({
        name: 'admin_list',
        hash: '#id_' + event.formData.id,
      })
    },
  },
}
</script>
